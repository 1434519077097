import React from 'react';
import PropTypes from 'prop-types';
// import { withPrefix } from 'gatsby';

// const data = [
//   {
//     title: 'Strong UX',
//     subtitle: '<mark class="mark-main">Seamless</mark>',
//     description: 'From homepage to smallest tooltip we\'re smoothing things up to create really seamless experience.',
//     icon: `${withPrefix('/')}img/strengths/ux.svg`,
//   },
//   {
//     title: 'Cloud Stack',
//     subtitle: '<mark class="mark-second">Scale</mark>',
//     description: 'From task to prototype, from prototype to production we think about scaling: what will be scaled, how it will be scaled, when it will be scaled.',
//     icon: `${withPrefix('/')}img/strengths/cloud.svg`,
//   },
//   {
//     title: 'Perfect Code',
//     subtitle: '<mark class="mark-main">Fit</mark>',
//     description: 'Best code is the code born as a top-notch fit on the edge of best practices, business requirements and reasonable efficiency.',
//     icon: `${withPrefix('/')}img/strengths/code.svg`,
//   },
//   {
//     title: 'Awesome UI',
//     subtitle: '<mark class="mark-second">Wow!</mark>',
//     description: 'When you need to impress the customers and you need them to be astonished our designers provide you with the solution. Batteries included!',
//     icon: `${withPrefix('/')}img/strengths/ui.svg`,
//   },
// ];

const Strengths = ({ data }) => (
  <div className="container">
    <div className="section-grid columns-4">
      {data.map((e, i) => (
        <figure className="section-grid-item" key={i}>
          <div className="section-grid-icon">
            {/* <img src={e.icon} alt={e.title} /> */}
            <img src={e.image.publicURL} alt={e.title} />
          </div>
          <figcaption className="section-grid-desc">
            <h4>
              {e.title}
              <span dangerouslySetInnerHTML={{ __html: e.subtitle }} />
            </h4>
          </figcaption>
        </figure>
      ))}
    </div>
  </div>
);

Strengths.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default Strengths;
