import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';

import Layout from '../components/Layout';
import Strengths from '../components/Strengths';
import Home from '../components/Home';
import Products from '../components/Products';
import Technologies from '../components/Technologies';
import Experience from '../components/Experience';
import Plan from '../components/Plan';
import Form from '../components/FormSend';
import Modal from '../components/Modal';

import infoSectionBg1 from '../../static/img/info/info-section-bg1.jpg';
// import infoSectionBg2 from '../../static/img/info/info-section-bg2.jpg';
// import infoSectionBg3 from '../../static/img/info/info-section-bg3.jpg';

import productsSectionImage from '../../static/img/products/developer.svg';
import experienceSectionImage from '../../static/img/experience/developer.svg';
import formSectionImage from '../../static/img/form/deal.svg';

import '../styles/styles.scss';
import Button from '../components/Button';


const redirectToAboutPage = () => navigate('/about');

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const [isOpen, setIsOpen] = useState(false);
  const [orderType, setOrderType] = useState(false);

  const toggleModal = (newOrderType) => {
    setOrderType(newOrderType);
    setIsOpen(!isOpen);
  };
  return (
    <Layout>
      <section className="Section__home">
        <Home data={frontmatter.services} onClick={toggleModal} title={frontmatter.title} />
      </section>
      <section id="whatWedo" className="Section__products">
        <div className="container">
          <div className="section-header">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{ __html: frontmatter.products.title }}
            />
            <Products data={frontmatter.products.list} />
          </div>
          <Button
            title="About us"
            onClick={redirectToAboutPage}
          />

        </div>
        <div className="Section__products-row">
          <img
            src={productsSectionImage}
            alt="SolidOne"
          />
        </div>
      </section>
      <section className="Section__info" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
        <div className="container">
          <blockquote>
            <p className="section-subtitle">{frontmatter.info.description1}</p>
          </blockquote>
        </div>
        <span className="Section__info-line left" />
      </section>

      <section className="Section__strengths">
        <Strengths data={frontmatter.strengths.list} />
      </section>

      <section className="Section__technologies">
        <div className="container">
          <Technologies data={frontmatter.technologies.list} />
        </div>
      </section>

      <section id="solutions" className="Section__plan">
        <Plan
          toggleModal={toggleModal}
          data={frontmatter.plan.list}
        />
      </section>

      <section className="Section__experience">
        <div className="container">
          <div className="section-header">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{ __html: frontmatter.experience.title }}
            />
            <Experience data={frontmatter.experience.list} />
          </div>
        </div>
        <div className="Section__experience-row">
          <img
            src={experienceSectionImage}
            alt="SolidOne"
          />
        </div>
      </section>



      <section id="contact" className="Section__form">
        <div className="container">
          <div className="Section__form-grid">
            <div className="Section__form-item">
              <h2>{frontmatter.form.title}</h2>
              <Form btnTitle='Order a solution' btnSizeMD />
            </div>
            <div className="Section__form-item">
              <img
                className="Section__form-img"
                src={formSectionImage}
                alt="SolidOne"
              />
            </div>
          </div>
        </div>
        <span className='Section__form-line' />
      </section>

      <Modal
        isOpen={isOpen}
        toggleModal={toggleModal}
        modalTitle={frontmatter.modalTitle}
        initialOrderValue={orderType}
      />
    </Layout>
  )
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        modalTitle
        title
        services { 
          list {
            title
            image {
              publicURL
            }
          }
        }
        achievements {
          description
        }
        info {
          description1
          description2
          description3
        }
        strengths {
          list {
            title
            subtitle
            description
            image {
              publicURL
            }
          }
        }
        products {
          title
          list {
            title
          }
        }
        technologies {
          title
          list {
            title
            image {
              publicURL
            }
            spot {
              publicURL
            }
          }
        }
        experience {
          title
          list {
            title
          }
        }
        plan {
          list {
            title
            subtitle
            items
            orderType
          }
        }
        form {
          title
        }
      }
    }
  }
`
