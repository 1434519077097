import React from 'react';
import PropTypes from 'prop-types';
import WorldMap from '../WorldMap';
import Button from '../Button';

// todo: responsiveness of services
const Home = ({ data, title = '', onClick }) => {
  return (
    <>
      <div className="map-container">
        <div className="map">
          <WorldMap />
        </div>
        <div className="title-container">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <Button
            title="Order a solution"
            onClick={onClick} />
        </div>
      </div>
      <div className="services">
        {data.list.map(
          (e) => (
            <img src={e.image.publicURL} alt={e.title} />
          )
        )}
      </div>
    </>
  );
};

Home.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      subtitle: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default Home;